import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
    ResponseResource,
    ResponseResourceCollection,
} from '@service/http/response';
import {
    TipCompetitionUser,
    TipCompetitionUserProps,
    TipCompetitionUserRequestParameters,
    TipCompetitionUserStoreRequestParameters,
} from '@service/tip-competition-user/tip-competition-user';

export enum UserTipCompetitionsActionType {
    LOAD_USER_TIP_COMPETITIONS = '[USER TIP COMPETITIONS] Load user tip competitions',
    LOAD_USER_TIP_COMPETITIONS_SUCCESS = '[USER TIP COMPETITIONS] Load user tip competitions success',
    LOAD_USER_TIP_COMPETITIONS_FAILURE = '[USER TIP COMPETITIONS] Load user tip competitions failure',

    CREATE_USER_TIP_COMPETITIONS = '[USER TIP COMPETITIONS] Create user tip competitions',
    CREATE_USER_TIP_COMPETITIONS_SUCCESS = '[USER TIP COMPETITIONS] Create user tip competitions success',
    CREATE_USER_TIP_COMPETITIONS_FAILURE = '[USER TIP COMPETITIONS] Create user tip competitions failure',

    DELETE_USER_TIP_COMPETITIONS = '[USER TIP COMPETITIONS] Delete user tip competitions',
    DELETE_USER_TIP_COMPETITIONS_SUCCESS = '[USER TIP COMPETITIONS] Delete user tip competitions success',
    DELETE_USER_TIP_COMPETITIONS_FAILURE = '[USER TIP COMPETITIONS] Delete user tip competitions failure',
}

export const loadUserTipCompetitionsAction = createAction(
    UserTipCompetitionsActionType.LOAD_USER_TIP_COMPETITIONS,
    props<TipCompetitionUserProps<TipCompetitionUserRequestParameters>>(),
);

export const loadUserTipCompetitionsSuccessAction = createAction(
    UserTipCompetitionsActionType.LOAD_USER_TIP_COMPETITIONS_SUCCESS,
    props<ResponseResourceCollection<TipCompetitionUser>>(),
);

export const loadUserTipCompetitionsFailureAction = createAction(
    UserTipCompetitionsActionType.LOAD_USER_TIP_COMPETITIONS_FAILURE,
    props<{
        error: HttpErrorResponse;
    }>(),
);

export const createUserTipCompetitionsAction = createAction(
    UserTipCompetitionsActionType.CREATE_USER_TIP_COMPETITIONS,
    props<TipCompetitionUserProps<TipCompetitionUserStoreRequestParameters>>(),
);

export const createUserTipCompetitionsSuccessAction = createAction(
    UserTipCompetitionsActionType.CREATE_USER_TIP_COMPETITIONS_SUCCESS,
    props<ResponseResource<TipCompetitionUser>>(),
);

export const createUserTipCompetitionsFailureAction = createAction(
    UserTipCompetitionsActionType.CREATE_USER_TIP_COMPETITIONS_FAILURE,
    props<{
        error: HttpErrorResponse;
    }>(),
);

export const deleteUserTipCompetitionsAction = createAction(
    UserTipCompetitionsActionType.DELETE_USER_TIP_COMPETITIONS,
    props<{ id: number }>(),
);

export const deleteUserTipCompetitionsSuccessAction = createAction(
    UserTipCompetitionsActionType.DELETE_USER_TIP_COMPETITIONS_SUCCESS,
    props<{ id: number }>(),
);

export const deleteUserTipCompetitionsFailureAction = createAction(
    UserTipCompetitionsActionType.DELETE_USER_TIP_COMPETITIONS_FAILURE,
    props<{ id: number }>(),
);
